/* eslint-disable snakecasejs/snakecasejs */

import { IIVT } from './vendor/iivt.js';
import './vendor/smoothscroll.min.js';
import './vendor-solo/collapsed-form-items.js';
import './vendor-solo/drag-drop-files.js';

( () => {
	// Stop double form submission
	const forms = document.querySelectorAll( 'form' );

	for ( let y = 0; y < forms.length; y++ ) {
		forms[y].addEventListener( 'submit', ( e ) => {
			return preventDoubleSubmission( forms[y], e );
		} );
	}

	function preventDoubleSubmission( form, e ) {
		if ( form.getAttribute( 'data-submitting' ) ) {
			e.preventDefault();
		} else {
			form.setAttribute( 'data-submitting', true );
			form.classList.add( 'form--submitting' );
		}
	}

} )();

( () => {
	// Financial assistance
	const financialCheckbox = document.getElementById( 'financialAssistance' );
	const choicesBox = document.getElementById( 'payment-choice' );

	if ( !financialCheckbox || !choicesBox ) {
		return;
	}

	financialCheckbox.addEventListener( 'change', toggleChoicesBox );

	function toggleChoicesBox() {
		if ( financialCheckbox.checked ) {
			choicesBox.classList.add( 'removed' );
		} else {
			choicesBox.classList.remove( 'removed' );
		}
	}


} )();

( () => {

	// Donation totalling
	const totalValueElem = document.getElementById( 'js-ticket-value' );
	const donationValueElem = document.getElementById( 'js-total-donation' );
	const donationAmounts = document.getElementById( 'js-donation-values' );
	const donationAmountCustom = document.getElementById( 'donationAmountCustom' );
	const basketTotal = document.getElementById( 'js-total-amount' );
	const headerTotal = document.getElementById( 'js-info-cost' );

	if ( !donationAmounts || !donationAmountCustom || !basketTotal || !donationValueElem ) {
		return;
	}

	const allDonationAmounts = donationAmounts.querySelectorAll( 'input' );
	for ( let i = 0; i < allDonationAmounts.length; i++ ) {
		allDonationAmounts[i].addEventListener( 'change', calculateBasketTotal );
	}

	donationAmountCustom.addEventListener( 'input', calculateBasketTotal );

	function calculateBasketTotal() {
		if ( donationAmounts.querySelector( ':checked' ) ) {
			let totalValue = parseFloat( totalValueElem.innerHTML );
			const baseValue = ( totalValue > 0 ) ? '£' + totalValue.toFixed( 2 ) : '';
			let donationValue = '';
			if ( donationAmounts.querySelector( ':checked' ).value === 'Other' ) {
				if ( donationAmountCustom.value === '' ) {
					donationValue = 0;
				} else {
					donationValue = parseFloat( donationAmountCustom.value );
				}
			} else {
				donationValue = parseFloat( donationAmounts.querySelector( ':checked' ).value );
			}
			totalValue = totalValue + donationValue;
			donationValueElem.innerHTML = ( donationValue > 0 ) ? '£' + donationValue.toFixed( 2 ) : '';
			basketTotal.innerHTML = ( totalValue > 0 ) ? '£' + totalValue.toFixed( 2 ) : '';
			if ( headerTotal && donationValue > 0 ) {
				headerTotal.innerHTML = baseValue + ' + £' + donationValue.toFixed( 2 ) + ' donation';
			} else {
				headerTotal.innerHTML = baseValue;
			}
		}
	}

	calculateBasketTotal();

} )();


( () => {
	// File upload previews
	const imageFileField = document.getElementById( 'contact-image' );
	const imageCacheField = document.getElementById( 'contact-image-cache' );
	const imagePreviewField = document.getElementById( 'preview-image' );
	const imageRemoveButton = document.getElementById( 'remove-preview-image' );
	if ( !imageFileField || !imageRemoveButton || !imagePreviewField ) {
		return;
	}
	imageFileField.addEventListener( 'change', readURL( imageFileField ) );
	imageRemoveButton.addEventListener( 'click', resetFileInput( imageFileField ) );
	function resetFileInput( input ) {
		return () => {
			imagePreviewField.parentElement.classList.remove( 'form__preview-image--active' );
			imageCacheField.value = null;
			if ( input.files && input.files[0] ) {
				input.value = null;
			}
		};
	}
	function readURL( input ) {
		return () => {
			if ( input.files && input.files[0] ) {
				const reader = new FileReader();
				reader.onload = function( e ) {
					imagePreviewField.setAttribute( 'src', e.target.result );
					imagePreviewField.parentElement.classList.add( 'form__preview-image--active' );
				};
				reader.onprogress = function( e ) {
					if ( e.lengthComputable ) {
						let progress = parseInt( ( ( e.loaded / e.total ) * 100 ), 10 );
					}
				};
				reader.readAsDataURL( input.files[0] );
			}
		};
	}
} )();

( () => {
	// Child switcher autofocus switch button
	const child_switcher = document.getElementById( 'js-child-switcher' );
	const child_switcher_submit = document.getElementById( 'js-child-switch-submit' );
	if ( !child_switcher || !child_switcher_submit ) {
		return;
	}
	child_switcher_submit.disabled = true;
	child_switcher.addEventListener( 'change', () => {
		child_switcher_submit.disabled = false;
		child_switcher_submit.focus();
	} );
} )();

( () => {
	// Mobile nav toggle
	const mobile_toggle = document.getElementById( 'js-nav-toggle' );
	if ( !mobile_toggle ) {
		return;
	}
	mobile_toggle.addEventListener( 'click', () => {
		document.body.classList.toggle( 'nav-open' );
	} );
} )();

( () => {
	// Cookie notice
	if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
		document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000;secure';
		document.body.classList.add( 'show-cookie-notice' );
		document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
			document.body.classList.remove( 'show-cookie-notice' );
		} );
	}
} )();

( () => {
	// Register/login homepage toggle
	const showLogin = document.getElementById( 'js-toggle-login' );
	const showRegister = document.getElementById( 'js-toggle-register' );

	if ( !showLogin || !showRegister ) {
		return;
	}

	const login = document.getElementById( 'welcome-form-login' );
	const register = document.getElementById( 'welcome-form-register' );

	showLogin.addEventListener( 'click', swapItems( login, register, showLogin, showRegister ) );
	showRegister.addEventListener( 'click', swapItems( register, login, showRegister, showLogin ) );

	function swapItems( item, swapItem, toggler, swapToggler ) {
		return () => {
			if ( item.classList.contains( 'removed' ) ) {
				item.classList.remove( 'removed' );
				toggler.classList.remove( 'form__toggler--inactive' );
				item.setAttribute( 'aria-expanded', true );
				swapItem.classList.add( 'removed' );
				swapToggler.classList.add( 'form__toggler--inactive' );
				swapItem.setAttribute( 'aria-expanded', false );
			}
		};
	}

} )();

( () => {
	const financialAssistance = document.getElementById( 'financialAssistance' );
	const submitBtn = document.getElementById( 'js-form-submit-btn' );
	if ( !financialAssistance ) {
		return;
	}
	financialAssistance.addEventListener( 'change', () => {
		checkAndSwapSubmitValue();
	} );
	function checkAndSwapSubmitValue() {
		if ( financialAssistance.checked === true ) {
			submitBtn.value = 'Complete booking';
		} else {
			submitBtn.value = 'Continue to payment';
		}
	}
	checkAndSwapSubmitValue();
} )();

( () => {

	const clientForms = document.querySelectorAll( '[data-validated]' );

	const validator = new IIVT();

	for ( let i = 0; i < clientForms.length; i++ ) {
		IIVTHandler( clientForms[i] );
	}

	function IIVTHandler( form ) {
		if ( !form ) {
			return;
		}
		const inputs = form.querySelectorAll( '[data-validators]' );
		validator.addValidators( {
			not_empty: function( input ) {
				return input.value.trim() !== '';
			},
			email: function( input ) {
				return /.+@.+\..+/.test( input.value.trim() );
			},
			longerthan6: function( input ) {
				return ( input.value.trim() ).length >= 6;
			},
			digits: function( input ) {
				return !( /[^\d \+]/.test( input.value ) );
			},
			postcode: function( input ) {
				return /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i.test( input.value.trim() );
			},
			is_checked: function( input ) {
				return !!input.checked;
			}
		} );
		function validateInput( input, submitted ) {
			const valid = validator.isValid( input, submitted );
			if ( valid.valid ) {
				validator.clearMessage( input );
			} else {
				validator.displayError( input, valid );
			}
			return valid.valid;
		}
		function handleFormSubmit( event ) {
			return function( event ) {
				let form_is_valid = true;
				for ( let i = inputs.length - 1; i >= 0; i -= 1 ) {
					const input_is_valid = validateInput( inputs[i], true );
					form_is_valid = form_is_valid && input_is_valid;
				}
				if ( form_is_valid ) {
					return true;
				}
				event.preventDefault ? event.preventDefault() : event.returnValue = false;
				const position = form.getBoundingClientRect().top;
				const container = document.getElementById( 'scroll-container' );
				form.removeAttribute( 'data-submitting' );
				form.classList.remove( 'form--submitting' );
				container.scrollTo( {
					top: position,
					behavior: 'smooth',
				} );
				return false;
			};
		}
		function handleInputChange( input, timeout ) {
			return () => {
				window.setTimeout( () => {
					validateInput( input, false );
				}, timeout );
			};
		}
		form.addEventListener( 'submit', handleFormSubmit() );
		for ( let i = inputs.length - 1; i >= 0; i -= 1 ) {
			inputs[i].addEventListener( 'keydown', validator.clearMessage( inputs[i] ) );
			inputs[i].addEventListener( 'change', validator.clearMessage( inputs[i] ) );
			inputs[i].addEventListener( 'blur', handleInputChange( inputs[i], 1 ) );
		}
	}

} )();

