
// IIVT?
export class IIVT {
	constructor() {
		this.validators = {};
		this.messages = {
			'firstName': {
				'not_empty': 'Please enter your first name'
			},
			'lastName': {
				'not_empty': 'Please enter your last name'
			},
			'email': {
				'not_empty': 'Please enter your email address',
				'email': 'Please enter a valid email address'
			},
			'password': {
				'not_empty' : 'Please enter your password',
				'longerthan6' : 'Your password should contain at least 6 characters'
			},
			'passwordConfirm': {
				'not_empty' : 'Please repeat your password'
			},
			'childFirstName': {
				'not_empty' : 'Please enter your child\'s last name'
			},
			'childLastName': {
				'not_empty' : 'Please enter your child\'s last name'
			},
			'contactConsent': {
				'not_empty' : 'Please choose a contact preference'
			},
			'postcode': {
				'not_empty' : 'Please enter a postcode',
				'postcode' : 'Please enter a valid UK postcode'
			},
			'telephone': {
				'digits' : 'Please enter a phone number',
				'not_empty' : 'Please enter a phone number'
			},
			'address': {
				'not_empty' : 'Please enter a street address'
			},
			'townCity': {
				'not_empty' : 'Please enter a town/city'
			},
			'county': {
				'not_empty' : 'Please enter a county'
			},
			'eventAttendeeEducationalInstitution': {
				'not_empty' : 'Please enter a most recent place of education'
			},
			'eventAttendeeEmergencyContactName1': {
				'not_empty' : 'Please name an Emergency Contact name'
			},
			'eventAttendeeEmergencyContactPhone1': {
				'not_empty' : 'Please enter an Emergency Contact phone number'
			},
			'eventAttendeeEmergencyContactEmail1': {
				'not_empty' : 'Please enter an Emergency Contact email address'
			},
			'eventAttendeeEmergencyContactName2': {
				'not_empty' : 'Please name a second Emergency Contact name'
			},
			'eventAttendeeEmergencyContactPhone2': {
				'not_empty' : 'Please enter a second Emergency Contact phone number'
			},
			'eventAttendeeEmergencyContactEmail2': {
				'not_empty' : 'Please enter a second Emergency Contact email address'
			},
			'eventAttendeeDoctorName': {
				'not_empty' : 'Please enter your doctor\'s name'
			},
			'eventAttendeeDoctorPhone': {
				'not_empty' : 'Please enter your doctor\'s phone number'
			},
		};
	}

	addValidators( validator_array ){
		for( var validator in validator_array ){
			if( !validator_array.hasOwnProperty( validator ) ){
				continue;
			}
			this.validators[validator] = validator_array[validator];
		}
	}

	clearMessage( input ){
		return function(){
			var sibling = input.nextElementSibling;
			input.classList.remove( 'error' );
			if( input.classList.contains( 'form__select' ) ) {
				input.parentElement.classList.remove( 'form__select-wrapper--error' );
			}
			if( sibling && sibling.className === 'form__message form__message--error' ) {
				sibling.innerHTML = '';
				sibling.classList.add( 'form__message--hidden' );
			}
		};
	}

	displayError( input, valid ){
		var sibling = input.nextElementSibling;
		input.classList.add( 'error' );
		if( input.classList.contains( 'form__select' ) ) {
			input.parentElement.classList.add( 'form__select-wrapper--error' );
		}
		if( !sibling || !( sibling.classList.contains( 'form__message' ) ) ) {
			sibling = document.createElement('SPAN');
			sibling.className = 'form__message form__message--error';
			sibling.setAttribute( 'role', 'alert' );
			input.parentNode.insertBefore(sibling,input.nextElementSibling);
		}
		else {
			sibling.className = 'form__message form__message--error';
		}
		sibling.innerHTML = valid.message;
	}

	addMessages( allMessages ){
		this.messages = allMessages;
	}

	isValid( input, submitted ){
		var is_valid = true;
		var message = '';
		var rule_validity = {};
		var enabled_validators = input.getAttribute( 'data-validators' );
		if( enabled_validators !== null ){
			enabled_validators = enabled_validators.split( '|' );
			for( var i = 0; i < enabled_validators.length; i += 1 ){
				if( !this.validators.hasOwnProperty( enabled_validators[i] ) ){
					continue;
				}
				if ( enabled_validators[i] == 'not_empty' && !submitted ) {
					continue;
				}
				var validation_result = this.validators[enabled_validators[i]]( input );
				rule_validity[enabled_validators[i]] = validation_result;
				is_valid = is_valid && validation_result;
				if (!validation_result) {
					message = ( this.messages[input.name][enabled_validators[i]] );
					break;
				}
			}
		}
		return {
			valid: is_valid,
			message: message,
			rules: rule_validity
		};
	}
}
