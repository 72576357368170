
( () => {

	const radioElements = document.querySelectorAll( '.form__radiogroup--has-child' );
	if ( !radioElements ) {
		return;
	}
	bindHiddenRadioGroupElements( radioElements );

} )();

( () => {

	const selectElements = document.querySelectorAll( '.form__select--has-child' );
	if ( !selectElements ) {
		return;
	}
	bindHiddenSelectElements( selectElements );

} )();

function bindHiddenRadioGroupElements( elements ) {
	for ( let i = 0; i < elements.length; i++ ) {
		const items = elements[i].querySelectorAll( '.form__custom-radio-input' );
		for ( let j = 0; j < items.length; j++ ) {
			items[j].addEventListener( 'change', revealCollapsedElementWithRadio( elements[i], items[j] ) );
			if ( items[j].checked ) {
				revealCollapsedElementWithRadio( elements[i], items[j] )();
			}
		}
	}
};

function bindHiddenSelectElements( elements ) {
	for ( let i = 0; i < elements.length; i++ ) {
		elements[i].addEventListener( 'change', revealCollapsedElementWithSelect( elements[i] ) );
		if ( elements[i].getAttribute( 'data-child-trigger' ) === elements[i].value ) {
			revealCollapsedElementWithSelect( elements[i] )();
		}
	}
};

function revealCollapsedElementWithSelect( trigger ) {
	return () => {
		const elemToToggleName = trigger.getAttribute( 'data-child' );
		const elemToToggle = document.getElementById( elemToToggleName );
		const triggerValue = trigger.getAttribute( 'data-child-trigger' );
		if ( !elemToToggle || !triggerValue ) {
			return;
		}
		if ( trigger.value == triggerValue ) {
			elemToToggle.classList.remove( 'form__collapsible--collapsed' );
			elemToToggle.setAttribute( 'aria-expanded', true );
			return;
		}
		elemToToggle.setAttribute( 'aria-expanded', false );
		elemToToggle.classList.add( 'form__collapsible--collapsed' );
	}
};

function revealCollapsedElementWithRadio( fieldset, childChanged ) {
	return () => {
		const elemToToggleName = fieldset.getAttribute( 'data-child' );
		const elemToToggle = document.getElementById( elemToToggleName );
		const triggerValue = fieldset.getAttribute( 'data-child-trigger' );
		if ( !elemToToggle || !triggerValue ) {
			return;
		}
		if ( childChanged.value == triggerValue ) {
			elemToToggle.classList.remove( 'form__collapsible--collapsed' );
			elemToToggle.setAttribute( 'aria-expanded', true );
			return;
		}
		elemToToggle.setAttribute( 'aria-expanded', false );
		elemToToggle.classList.add( 'form__collapsible--collapsed' );
	}
};