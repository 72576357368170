
( () => {

	const imagePreviewField = document.getElementById( 'preview-image' );
	const fileInput = document.querySelector( '.form__image-upload-label' );
	if ( !fileInput ) {
		return;
	}
	['dragenter', 'dragover', 'dragleave', 'drop'].forEach( eventName => {
		fileInput.addEventListener( eventName, preventDefaults, false );
		document.body.addEventListener( eventName, preventDefaults, false );
	} );

	['dragenter', 'dragover'].forEach( eventName => {
		fileInput.addEventListener( eventName, highlight, false );
	} );

	['dragleave', 'drop'].forEach( eventName => {
		fileInput.addEventListener( eventName, unhighlight, false );
	} );

	fileInput.addEventListener( 'drop', handleDrop, false );

	function preventDefaults( e ) {
		e.preventDefault();
		e.stopPropagation();
	}

	function handleDrop(e) {
		let dt = e.dataTransfer;
		let files = dt.files;
		uploadFile( files[0] );
	}

	function highlight(e) {
		fileInput.classList.add( 'form__image-upload-label--active' );
	}

	function unhighlight(e) {
		fileInput.classList.remove( 'form__image-upload-label--active' );
	}

	function uploadFile(file) {
		let formData = new FormData();
		const reader = new FileReader();
		reader.onload = function( e ) {
			imagePreviewField.setAttribute( 'src', e.target.result );
			imagePreviewField.parentElement.classList.add( 'form__preview-image--active' );
		};
		reader.readAsDataURL( file );
		formData.append( 'pictureBytes', file );
	}

} )();
